<script setup lang="ts">
import style from "src/config/style";
import {RouterView} from "vue-router";
import {applicationStore} from "src/store";
import {ConfigProvider, StyleProvider} from "ant-design-vue";

const config = applicationStore();
const theme = {
  token: {
    colorLink: style.primary,
    colorPrimary: style.primary
  },
  components: {
    Button: {
      colorPrimary: style.primary
    }
  }
};
</script>

<template>
  <ConfigProvider :component-size="config.componentSize" :locale="config.locale" :theme="theme">
    <StyleProvider hash-priority="low">
      <RouterView></RouterView>
    </StyleProvider>
  </ConfigProvider>
</template>