
interface Style {
  primary: string;
  black: string;
  white: string;
};

const style = (process.env.style || {}) as Style;


export default style;