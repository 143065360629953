/*
 * @Author: 17739673757@163.com
 * @Date: 2023-11-22 09:08:54
 * @LastEditors: zhangpengfei 17739673757@163.com
 * @LastEditTime: 2023-11-24 16:20:50
 * @Description:租户管理api
 */
import { PageResult } from "src/utils/model";
import { Http, Get, Post, tryError, required, validate } from "@js-lion/api";

import type { SearchArgs, addTenantFrom } from "src/types/tentant";

export default class {
  /**
   * 获取租户列表
   * @param pageNum       第几页
   * @param pageSize      每页记录数
   * @returns 
   */
  @tryError(new PageResult())
  @Get("/manager/organization/list")
  tenantList(SearchArgs: SearchArgs): Promise<PageResult> {
    // @ts-ignore
    return { params: SearchArgs };
  }


  /**
   * 添加租户
   * @param organizationId       租户id
   * @param organizationName      租户名称
   * @returns 
   */
  @Post("/manager/organization/save")
  tenantAdd(data: addTenantFrom): Promise<any> {
    // @ts-ignore
    const callback = function (result: object) {
      // todo
      // 该返回值会直接返回给 createUser 方法
      return result ? true : false;
    };
    // @ts-ignore
    return { data, callback };
  }

  /**
   * 删除租户
   * @param organizationId       租户id
   * @returns 
   */
  @Http("delete", "/manager/organization/delete/:id")
  @validate
  deleteTenant(@required organizationId: string | number): Promise<object> {
    const params = { id: organizationId };
    // @ts-ignore
    return { params };
  }

  /**
   * 改变租户状态
   * @param organizationId       租户id
   * @returns 
   */
  @validate
  @Post("/manager/organization/status/{organizationId}")
  @validate
  changeTenantStatus(@required organizationId: string | number): Promise<object> {
    const params = { organizationId }
    // @ts-ignore
    return { params };
  }
}

