
/**
 * @file 路由别名
 * @author svon.me@gmail.com
 */


import type { RouteRecordBase } from "src/types/common";


export * as root from "./root";
export * as admin from "./admin";
export * as normal from "./normal";

export * as user from "./user";

export const NotFount: RouteRecordBase = {
  name: "Not Fount",
  path: "404"
}