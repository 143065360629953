/**
 * @file 路由别名
 * @author svon.me@gmail.com
 */

import type {RouteRecordBase} from "src/types/common";

export const Login: RouteRecordBase = {
	name: "Login",
	path: "/login"
}
export const Reset: RouteRecordBase = {
	name: "User Reset",
	path: "/reset"
}
export const Register: RouteRecordBase = {
	name: "User Register",
	path: "/register"
}

export const Profile: RouteRecordBase = {
	name: "User Details Profile",
	path: "/user/profile"
}

export const Cipher: RouteRecordBase = {
	name: "User Cipher",
	path: "/user/cipher"
}

