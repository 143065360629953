// 权限对象
export interface Permission {
	id: number;
	parentId: number;
	title: string;
	icon: string;
	path: string;
	component: string;
}


export interface Module extends Permission {
	children?: Module[];
}

export enum OperatorType {
	root = 0,
	admin = 1,
	normal = 2,
}

export class Captcha {
}

export class UserModel {
	username: string = "root";
	password: string = "123456";
}

// 用户信息
export class UserInfo {
	realname!: string;
	nickname!: string;                 // 用户名
	gender?: number;   // 性别
	avatar?: string;   // 头像
	mobile?: string;
	email?: string;
	intro?: string;    // 简介
}