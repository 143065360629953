/**
 * @file 监听路由变化
 * @author svon.me@gmail.com
 */

import * as _ from "lodash-es";
import * as alias from "./alias";
import {userStore} from "src/store";
import type {NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded} from "vue-router";

export const beforeEach = function (to: RouteLocationNormalized, _from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) {
	const user = userStore();
	// 如果用户信息存在，并且当前路由为登录页面
	if (user.LoginStatus && to.name === alias.user.Login.name) {
		next(user.dashboard);
		return;
	}
	// 如果用户信息不存在，并且当前页面非登录页面
	if (!user.LoginStatus) {
		const user = [alias.user.Login.name, alias.user.Register.name, alias.user.Reset.name];
		if (_.includes(user, to.name)) {
			next();
		} else {
			next({name: alias.user.Login.name});
		}
		return;
	}

	let status = true;
	if (to.name === alias.NotFount.name) {
		status = true;
	}

	// 其余页面
	const matched = to.matched;
	if (status && matched && matched.length > 0) {
		document.title = to.name as string;
		next();
		return;
	} else {
		// 如果当前路由无匹配状态，则默认跳转至 404 页面
		next({name: alias.NotFount.name});
		return;
	}
}