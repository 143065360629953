/**
 * @file 租户管理台
 * @author svon.me@gmail.com
 */

import { defineStore } from 'pinia'

interface Module {
  moduleCode: string
  moduleId: number
  moduleName: string
  moduleType: string | number
}
class rootConfig {
  menu: Module[] = [
    {
      moduleCode: 'system_overview',
      moduleId: 1,
      moduleName: '多系统管理',
      moduleType: 2
    },
    {
      moduleCode: 'authority_audit',
      moduleId: 2,
      moduleName: '登录界面',
      moduleType: 2
    },
    {
      moduleCode: 'security_setting',
      moduleId: 3,
      moduleName: '安全中心',
      moduleType: 2
    },
    {
      moduleCode: 'application_setting',
      moduleId: 4,
      moduleName: '系统参数',
      moduleType: 2
    }
  ]
  name: string = '多系统管理'
}
export const rootStore = defineStore('rootStore', {
  state(): rootConfig {
    return new rootConfig()
  },
  getters: {
    // 登录状态
    LoginStatus: function (): boolean {
      return false
    }
  },
  actions: {}
})

