/**
 * @file 路由别名
 * @author svon.me@gmail.com
 */

import type { RouteRecordBase } from "src/types/common";

export const path = "/admin";

export const Dashboard: RouteRecordBase = {
  name: "Admin Dashboard",
  path: "dashboard",
}

// 菜单管理
export const Menu: RouteRecordBase = {
  name: "system/menu",
  path: "system/menu",
}

// 角色管理
export const Role: RouteRecordBase = {
  name: "system/role",
  path: "system/role",
}