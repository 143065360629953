import * as alias from "../alias";
import type {RouteRecordRaw} from "vue-router";

const router = function (): RouteRecordRaw {
	return {
		path: alias.normal.path,
		component: () => import("src/layout/normal/index.vue"),
		children: [
			{
				...alias.normal.Dashboard,
				component: () => import("src/pages/normal/dashboard.vue"),
			},
			{
				...alias.user.Profile,
				component: () => import("src/pages/normal/user/profile/index.vue"),
			},
			{
				...alias.user.Cipher,
				component: () => import("src/pages/normal/user/cipher/index.vue"),
			}
		],
	}
};

export default router;
