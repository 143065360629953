/**
 * @file 用户前台页面
 */
import type {RouteRecordBase} from "src/types/common";

export const path: string = "/";

// 首页
export const Dashboard: RouteRecordBase = {
	name: "Dashboard",
	path: "dashboard",
}