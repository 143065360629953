/**
 * @file 客户端
 * @author svon.me@gmail.com
 */

// 引入项目全局样式
import "ant-design-vue/dist/reset.css";
import "src/styles/main.less";


import {AppName} from "./config";
import {userStore} from "src/store";
import {http, HttpConfig} from "@ue/http";
import {createApp} from "./bootstrap/main";
import {API_BASE, API_Signature, AuthOrizationName} from "src/config";


http({
	timeout: 10 * 1000,      // 超时时长
	baseURL: API_BASE,      // 默认请求地址
	withCredentials: false, // 不携带 Cookie
}, new HttpConfig({}, API_Signature, AuthOrizationName));

const main = async function () {
	const {app, bootstrap} = createApp();

	const user = userStore();
	// 刷新用户数据
	await user.updateUserInfo();

	const router = bootstrap();
	await router.isReady(); // 加载路由;
	app.mount(`#${AppName}`);
};

setTimeout(main);
