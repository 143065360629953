/**
 * @file 权限管理
 * @author svon.me@gmail.com
 */
import Menu from "./menu";
import Role from "./role";
import {Gql} from "@js-lion/api";
import RoleMenu from "./role_menu";
import {RoleMenuGqlKeys, RoleUserGqlKeys} from "src/model/role";

import type {RoleMenu as MenuData, RoleUser} from "src/model/role";

interface Index {
	user: RoleUser;
	menus: MenuData[];
}

export default class {
	menu: Menu = new Menu();
	role: Role = new Role();
	roleMenu: RoleMenu = new RoleMenu();

	/**
	 * 权限列表
	 */
	@Gql("/graphql/query")
	list(): Promise<Index> {
		// 查询用户信息
		const data: string = `{
			user { ${RoleUserGqlKeys} }
      menus { ${RoleMenuGqlKeys} }
    }`;
		// @ts-ignore
		return {data};
	}
}