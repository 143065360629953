/**
 * @file 用户数据
 * @author svon.me@gmail.com
 */

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import {defineStore} from "pinia";
import zhCN from "ant-design-vue/es/locale/zh_CN";

import type {SizeType} from "ant-design-vue/es/config-provider";

class Config {
	container: number = 1400;
	locale: typeof zhCN = zhCN;
	componentSize: SizeType = "large";

	constructor() {
		dayjs.locale("zh-cn");
	}
}

export const applicationStore = defineStore("application", {
	state(): Config {
		const value = new Config();
		return {...value};
	},
	getters: {},
	actions: {}
});