/*
 * @Author:17739673757@163.com
 * @Date: 2023-12-20 16:08:07
 * @LastEditors: zhangpengfei 17739673757@163.com
 * @LastEditTime: 2023-12-27 15:30:52
 * @FilePath: \docs.web.2023\src\api\admin\notice.ts
 * @Description: 公告
 */

import * as _ from "lodash-es";
import * as model from "src/utils/model";
import DBList from "@fengqiaogang/dblist";
import { PageResult } from "src/utils/model";
import * as message from "@ue/message";
import { API, Post, Get,Delete, tryError, validate, required } from "@js-lion/api";

import type { SearchArgs } from "src/types/tentant";

export default class Notice {
  /**
   * 获取公告列表
   * @param pageNum       第几页
   * @param pageSize      每页记录数
   * @returns 
   */
  @tryError(new PageResult())
  @Post("/manager/notice/list")
  noticeList(data: SearchArgs): Promise<PageResult> {
    // @ts-ignore
    return { data };
  }
  /**
     * 获取搜索下拉列表
     * @returns 
     */
  @Get("/manager/select/operator")
  searchList() {
    return {};
  }

  @tryError(new model.PageResult<Node>())
  @Get("/manager/role/class/tree/1")
  tree(): Promise<model.PageResult<Node>> {
    const callback = function (list: object | object[]) {
      // 解析数据格式
      const db = new DBList<object>([]);
      db.insert(list, "subList");

      // 数据字段转换
      const temp = new DBList<Node>([], db.primary, db.foreign, db.foreignValue);
      temp.insert(db.clone().map(function (data: object): Node {
        // @ts-ignore
        const value: Node = { ...data, name: _.get(data, "treeNodeName") };
        return value;
      }));

      // 返回业务需要的数据格式
      return new model.PageResult<Node>(temp.childrenDeep(), temp.size());
    }
    // @ts-ignore
    return { callback };
  }

  /**
   * 添加公告
   * @param title       标题
   * @param noticeContent      详情
   * @returns 
   */
  @Post("/manager/notice/save")
  addNotice(data: any): Promise<any> {
    // @ts-ignore
    const callback = function (result: object) {
      // todo
      // 该返回值会直接返回给 createUser 方法
      return result ? true : false;
    };
    // 注解在发起 http 请求会，会将结果传给 callback 进行处理
    return { data, callback } as any;
  }

  /**
   * 删除公告
   * @param noticeId       公告id
   * @returns 
   */
  @message.$error()
  @message.$success("删除成功!")
  @Delete("/manager/notice/delete/{noticeId}")
  @validate
  deleteNotice(@required noticeId: string | number): Promise<object> {
    const params = {noticeId}
    return {params} as any;
  }

  /**
   * 获取公告信息
   * @param noticeId 公告ID
   * @param type       1：管理列表进入、2：首页未读列表进入（这种类型会记录已读信息）  
   * @returns 
   */
  @validate
  @Get("/manager/notice/info/{noticeId}/{type}")
  @validate
  getNoticeInfo(@required noticeId: string | number,@required type: string | number): Promise<object> {
    const params = { noticeId,type }
    // @ts-ignore
    return { params };
  }
}

