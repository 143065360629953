/**
 * @file 权限管理 - 菜单页面
 * @author svon.me@gmail.com
 */

import {$success, $error} from "@ue/message";
import safeGet from "@fengqiaogang/safe-get";
import {RoleMenuGqlKeys} from "src/model/role";
import {Post, Put, Delete, validate, required, tryError, Gql} from "@js-lion/api";

import type {RoleMenu} from "src/model/role";

export default class {
	/**
	 * 列表
	 */
	@Gql("/graphql/query")
	list(): Promise<RoleMenu[]> {
		// 查询用户信息
		const data: string = `{
      menus { ${RoleMenuGqlKeys} }
    }`;
		const callback = function (res: object) {
			return safeGet<RoleMenu[]>(res, "menus") || [];
		}
		// @ts-ignore
		return {data, callback};
	}

	/**
	 * 创建
	 */
	@tryError(false)
	@$error("创建失败, 请稍后在试")
	@$success("创建成功")
	@Post("/permission/menu/create")
	@validate
	create(@required data: RoleMenu): Promise<boolean> {
		// @ts-ignore
		return {data};
	}

	/**
	 * 编辑
	 */
	@tryError(false)
	@$error("编辑失败, 请稍后在试")
	@$success("编辑成功")
	@Put("/permission/menu/update")
	@validate
	update(@required data: RoleMenu): Promise<boolean> {
		// @ts-ignore
		return {data};
	}

	/**
	 * 删除
	 */
	@tryError(false)
	@$error("删除失败, 请稍后在试")
	@$success("删除成功")
	@Delete("/permission/menu/delete")
	@validate
	remove(@required id: string | number): Promise<boolean> {
		const params = {id};
		// @ts-ignore
		return {params};
	}
}