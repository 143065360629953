/**
 * @file 配置信息
 * @author svon.me@gmail.com
 */

import safeGet from "@fengqiaogang/safe-get";

const env = import.meta.env || {};

// 项目名称
export const AppName = safeGet<string>(env, "VITE_APP_NAME") || "";
// 接口签名名称
export const AuthOrizationName = safeGet<string>(env, "VITE_AUTH_ORIZATION_NAME") || "";
// cookie 名称
export const API_Signature = safeGet<string>(env, "VITE_API_SIGNATURE") || "";
// 接口基础路径
export const API_BASE = safeGet<string>(env, "VITE_API_BASE") || "";
