/**
 * @file 路由
 * @author svon.me@gmail.com
 */

import * as _ from "lodash-es";
import * as alias from "./alias";
import type { Router, RouteRecordRaw } from "vue-router";
import { createRouter as _createRouter, createWebHistory } from "vue-router";

const children: RouteRecordRaw[] = [];
const modules = import.meta.glob('./system/**/*.ts', { eager: true });

// 加入到路由集合中
Object.keys(modules).forEach((key: string) => {
  let value: RouteRecordRaw | RouteRecordRaw[] | undefined = void 0;
  const module = _.get(modules, key) as any;
  if (module && module.default) {
    value = module.default();
  } else if (module && typeof module === "function") {
    value = module();
  } else if (module && typeof module === "object"){
    value = module as RouteRecordRaw;
  }
  if (value && Array.isArray(value)) {
    children.push(...value);
  } else if (value) {
    children.push(value);
  }
});


const routes: RouteRecordRaw[]  = [
  {
    path: alias.user.Login.path,
    name: alias.user.Login.name,
    component: () => import("src/pages/common/user/login.vue")
  },
  {
    path: alias.user.Register.path,
    name: alias.user.Register.name,
    component: () => import("src/pages/common/user/register.vue")
  },
  {
    path: alias.user.Reset.path,
    name: alias.user.Reset.name,
    component: () => import("src/pages/common/user/reset.vue")
  },
  {
    path: "/",
    children: [...children, {
      ...alias.NotFount,
      component: () => import("src/pages/common/404.vue"),
    }],
  },
];

export function createRouter(): Router {
  return _createRouter({ routes, history: createWebHistory() });
}