/**
 * @file 用户数据
 * @author svon.me@gmail.com
 */

import api from "src/api";
import moment from "moment";
import cookie from "js-cookie";
import * as _ from "lodash-es";
import {defineStore} from "pinia";
import {API_Signature} from "src/config";
import * as alias from "src/router/alias";

import type {UserModel} from "src/types/user";
import type {RouteLocationRaw} from "vue-router";
import type {RoleMenu, RoleUser} from "src/model/role";


class Permission {
	info?: RoleUser;
	menus: RoleMenu[] = []
}

const initState = function () {
	const value = new Permission();
	return {
		info: value.info,
		menus: value.menus,
	};
}

export const userStore = defineStore("userStore", {
	state: initState,
	getters: {
		// 登录状态
		LoginStatus: function (): boolean {
			return !!(this.info && this.info.id);
		},
		dashboard: function (): RouteLocationRaw {
			// if (this.operatorType === OperatorType.root) {
			// 	return {name: alias.root.tenantList.name};
			// } else if (this.operatorType === OperatorType.admin) {
			// 	return {name: alias.admin.Dashboard.name};
			// } else {
			return {name: alias.normal.Dashboard.name};
			// }
		}
	},
	actions: {
		/**
		 * 退出
		 */
		async logOut() {
			await api.user.logOut();
			cookie.remove(API_Signature, {
				path: "/",
			});
			this.$reset();
		},
		// 登录
		async loadUserInfo(value: UserModel) {
			if (value) {
				const token: string | undefined = await api.user.logIn(value);
				if (token) {
					this.updateToken(token);
					return this.updateUserInfo(token);
				}
			}
			return false;
		},
		updateToken(token?: string) {
			const value = token ? token : cookie.get(API_Signature);
			if (value) {
				const expires = moment().add(12, "h").toDate();
				cookie.set(API_Signature, value, {
					expires,
					path: "/",
				});
			}
		},
		// 刷新用户信息
		async updateUserInfo(token?: string): Promise<boolean> {
			if (!token) {
				token = cookie.get(API_Signature);
			}
			if (token) {
				const res = await api.permission.list();
				if (res) {
					const menus: RoleMenu[] = _.map(res.menus, (item: RoleMenu) => {
						item.id = Number(item.id);
						item.type = Number(item.type);
						item.status = Number(item.status);
						item.parentid = Number(item.parentid);
						item.target = Number(item.target);
						item.sort = Number(item.target);
						return item;
					})
					this.$patch({info: res.user, menus});
					this.updateToken(token);
					return true;
				}
			}
			return false;
		}
	}
});