/**
 * @file Application
 * @author svon.me@gmail.com
 */

import application from "./app.vue";
import { createPinia } from "pinia";
import { createApp as create } from "vue";
import { createRouter } from "src/router/";
import { beforeEach } from "src/router/hook";
import Modal, { SetConfig } from "@ue/modal";

import type { App } from "vue";

/**
 * @file 创建 Vue 实例
 */
export function createApp() {
  const app: App = create(application);

  const store = createPinia();
  app.use(store);
  app.use(Modal);

  SetConfig({
    textAlign: "right",
    buttonClassName: "px-5 pb-5",
    wrapClassName: "deep-[.ant-modal-confirm-content]:w-full",
  });


  const bootstrap = function() {
    const router = createRouter();
    router.beforeEach(beforeEach);

    app.use(router);
    return router;
  }
  return { app, bootstrap };
}
