/**
 * @file 角色管理 - 菜单页面
 * @author svon.me@gmail.com
 */

import * as _ from "lodash-es";
import * as model from "src/utils/model";
import {$error, $success} from "@ue/message";
import safeGet from "@fengqiaogang/safe-get";
import {Delete, Gql, Post, Put, required, tryError, validate} from "@js-lion/api";

export default class {
	/**
	 * 列表
	 */
	@tryError([])
	@Gql("/graphql/query")
	list(): Promise<model.PageResult<object>> {
		// 查询用户信息
		const data: string = `{
			roles {
					id
					title
					status
					sort
					note
			}
    }`;
		const callback = function (res: object) {
			return safeGet<object[]>(res, "roles") || [];
		}
		// @ts-ignore
		return {data, callback};
	}

	/**
	 * 创建
	 */
	@tryError(false)
	@$error("创建失败, 请稍后在试")
	@$success("创建成功")
	@Post("/permission/role/create")
	@validate
	create(@required data: object): Promise<boolean> {
		// @ts-ignore
		return {data};
	}

	/**
	 * 编辑
	 */
	@tryError(false)
	@$error("编辑失败, 请稍后在试")
	@$success("编辑成功")
	@Put("/permission/role/update")
	@validate
	update(@required data: any): Promise<boolean> {
		data.id = _.toNumber(data.id);
		data.sort = _.toNumber(data.sort);
		data.status = _.toNumber(data.status);
		// @ts-ignore
		return {data};
	}

	/**
	 * 删除
	 */
	@tryError(false)
	@$error("删除失败, 请稍后在试")
	@$success("删除成功")
	@Delete("/permission/role/delete")
	@validate
	remove(@required id: string | number): Promise<boolean> {
		const params = {id};
		// @ts-ignore
		return {params};
	}
}