/*
 * @Author: 17739673757@163.com
 * @Date: 2023-11-22 09:08:54
 * @LastEditors: zhangpengfei 17739673757@163.com
 * @LastEditTime: 2023-12-13 17:05:17
 * @Description:租户管理api
 */
import * as _ from 'lodash-es'
import * as model from 'src/utils/model'
import * as message from '@ue/message'
import {treeAmend} from 'src/utils'
import {PageResult} from 'src/utils/model'
import {Get, Post, tryError, required, validate, API} from '@js-lion/api'

import type {SearchArgs} from 'src/types/tentant'

export default class Role {
	/**
	 * 获取租户列表
	 * @param pageNum       第几页
	 * @param pageSize      每页记录数
	 * @returns
	 */
	@tryError(new PageResult())
	@Post('/manager/operator/list')
	peopleList(data: SearchArgs): Promise<PageResult> {
		// @ts-ignore
		return {data}
	}

	/**
	 * 获取搜索下拉列表
	 * @returns
	 */
	@Get('/manager/select/operator')
	searchList() {
		return {}
	}

	@tryError(new model.PageResult<Node>())
	@Get('/manager/role/class/tree/{type}')
	tree(@required type: string | number): Promise<model.PageResult<Node>> {
		const params = {type}
		const callback = function (result: Node | Node[]) {
			// 解析数据格式
			const list = treeAmend<Node>(result, 'treeNodeId', 'subList')
			// 返回业务需要的数据格式
			return new model.PageResult<Node>(list)
		}
		// @ts-ignore
		return {params, callback}
	}

	/**
	 * 批量新增角色分类
	 * @param name     角色分类名称
	 * @param parentId 父级角色分类ID
	 * @param desc     部门描述内容
	 * @param type     类型（1：控制台管理员、2：文档系统角色）
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('添加成功!')
	@Post('/manager/role/class/save/batch')
	@validate
	insertClassBatch(@required name: string | string[], @required parentId: Array<string | number> | string | number, desc?: string): Promise<boolean> {
		const data = {
			remark: desc,
			roleClassNames: _.concat([], name),
			roleParentClassId: _.concat([], parentId)[0],
			type: 1
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 批量新增角色
	 * @param name     角色名称
	 * @param parentId 父级角色ID
	 * @param desc     描述内容
	 * @param type     类型（1：控制台管理员、2：文档系统角色）
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('添加成功!')
	@Post('/manager/role/save/batch')
	@validate
	insertBatch(@required name: string | string[], @required parentId: Array<string | number> | string | number, desc?: string): Promise<boolean> {
		const data = {
			remark: desc,
			roleNames: _.concat([], name),
			roleClassId: _.concat([], parentId)[0],
			type: 1
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 编辑更新角色分类
	 * @param name     角色分类名称
	 * @param parentId 父级角色ID
	 * @param desc     描述内容
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('角色分类名称已更新!')
	@Post('/manager/role/class/save')
	@validate
	classUpdate(@required id: string | number, @required name: string, @required parentId: string | number | Array<string | number>, desc?: string): Promise<boolean> {
		const data = {
			remark: desc,
			roleClassId: id,
			roleClassName: name,
			// @ts-ignore
			roleParentClassId: _.concat([], parentId)[0],
			type: 1
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 编辑更新角色
	 * @param name     角色分类名称
	 * @param parentId 父级角色ID
	 * @param desc     描述内容
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('角色名称已更新!')
	@Post('/manager/role/save')
	@validate
	Update(@required id: string | number, @required name: string, @required parentId: string | number | Array<string | number>, desc?: string): Promise<boolean> {
		const data = {
			remark: desc,
			roleId: id,
			roleName: name,
			// @ts-ignore
			roleClassId: _.concat([], parentId)[0],
			type: 1
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 删除角色分类
	 * @param name
	 */
	@tryError(false)
	@message.$error()
	@message.$success('删除成功 !')
	@validate
	async removeClass(@required id: string | number | Array<string | number>): Promise<boolean> {
		const api = new API()
		const list = _.concat([], id).map(function (value: string | number) {
			return api.delete(`/manager/role/class/delete/${value}`)
		})
		try {
			await Promise.all(list)
		} catch (error) {
			return Promise.reject(error)
		}
		return true
	}

	/**
	 * 删除角色
	 * @param name
	 */
	@tryError(false)
	@message.$error()
	@message.$success('删除成功 !')
	@validate
	async remove(@required id: string | number | Array<string | number>): Promise<boolean> {
		const api = new API()
		const list = _.concat([], id).map(function (value: string | number) {
			return api.delete(`/manager/role/delete/${value}`)
		})
		try {
			await Promise.all(list)
		} catch (error) {
			return Promise.reject(error)
		}
		return true
	}

	/**
	 * 移动角色分类
	 * @param sourceIds     选中要移动的ID集合
	 * @param targetId 要移动到的目标部门ID
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('移动成功!')
	@Post('/manager/role/move/role-class')
	@validate
	moveClass(@required sourceIds: Array<string | number>, @required targetId: string): Promise<boolean> {
		const data = {
			sourceIds: _.concat([], sourceIds),
			targetId: targetId
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 复制角色分类
	 * @param sourceIds     选中要移动的ID集合
	 * @param targetIds 要移动到的目标部门ID
	 * @param copyOperator 是否复制角色人员(1:是、0:否)
	 * @param subClass 是否复制子分类(1:是、0:否)
	 * @param subRole 是否复制子角色(1:是、0:否)
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('复制成功!')
	@Post('/manager/role/copy/role-class')
	@validate
	copyClass(@required sourceIds: Array<string | number>, @required targetIds: Array<string | number>, copyOperator?: string | number, subClass?: string | number, subRole?: string | number): Promise<boolean> {
		const data = {
			sourceIds: _.concat([], sourceIds),
			targetIds: _.concat([], targetIds),
			copyOperator: copyOperator,
			subClass: subClass,
			subRole: subRole
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 复制角色
	 * @param sourceIds     选中要移动的ID集合
	 * @param targetIds 要移动到的目标部门ID
	 * @param copyOperator 是否复制角色人员(1:是、0:否)
	 * @returns
	 */
	@tryError(false)
	@message.$error()
	@message.$success('复制成功!')
	@Post('/manager/role/copy/role')
	@validate
	copy(@required sourceIds: Array<string | number>, @required targetIds: Array<string | number>, copyOperator?: string | number): Promise<boolean> {
		const data = {
			sourceIds: _.concat([], sourceIds),
			targetIds: _.concat([], targetIds),
			copyOperator: copyOperator
		}
		// @ts-ignore
		return {data}
	}

	/**
	 * 角色授权统计表
	 * @param pageNum       第几页
	 * @param pageSize      每页记录数
	 * @returns
	 */
	@tryError(new PageResult())
	@Post('/manager/role/role-operator')
	roleOperator(data: SearchArgs): Promise<PageResult> {
		// @ts-ignore
		return {data}
	}
}
