/**
 * @file 用户相关接口
 * @author svon.me@gmail.com
 */

import * as message from "@ue/message";
import safeGet from "@fengqiaogang/safe-get";
import {RoleUserGqlKeys} from "src/model/role";

import {Get, Post, Gql, API, required, validate, tryError} from "@js-lion/api";
import type {UserInfo, UserModel} from "src/types/user";

export default class {
	/**
	 * 登录
	 * @param account 用户信息
	 * @returns
	 */
	@tryError(void 0)                           // 监听整个请求的异常错误，如果有异常则返回默认值
	@message.$error("账号或密码错误, 请重新输入")       // 当请求有异常时在页面上提示错误信息, 优先展示接口中的 message 字段，如果为空时提示默认值
	@Post("/user/login")                                   // 请求类型与请求地址
	@validate                                             // 开启参数校验
	async logIn(@required account: UserModel): Promise<string | undefined> {
		const data = new FormData();
		for (const name in account) {
			const value = safeGet<string>(account, name);
			data.append(name, value || "");
		}
		data.append("remember", "true");
		// @ts-ignore
		return {data};
	}

	/**
	 * 用户详情
	 * @returns UserIfno
	 */
	@tryError(void 0)
	@Gql("/graphql/query")
	async info(): Promise<UserInfo | undefined> {
		// 查询用户信息
		const data: string = `{ user { ${ RoleUserGqlKeys } } }`;
		const callback = function (res: object) {
			return safeGet<UserInfo>(res, "user");
		}
		// @ts-ignore
		return {data, callback};
	}

	/**
	 * 退出
	 */
	@tryError(void 0)
	@Get("/logout")
	logOut(): Promise<void> {
		// @ts-ignore
		return {};
	}

	/**
	 * 修改密码
	 */
	@tryError(false)
	@message.$error("密码修改失败，请重试")
	@message.$success("密码修改成功")
	@validate
	updateCipher(@required password: string): Promise<boolean> {
		const data: FormData = new FormData();
		// 新密码
		data.append("newPassword", password);
		// 确认密码
		data.append("rePassword", password);
		const api: API = new API();
		return api.put("/updatePwd", data);
	}
}