import * as alias from "../alias";
import type {RouteRecordRaw} from "vue-router";

const router = function (): RouteRecordRaw {
	return {
		path: alias.admin.path,
		component: () => import("src/layout/admin/index.vue"),
		children: [
			{
				...alias.admin.Dashboard,
				component: () => import("src/pages/admin/dashboard/index.vue"),
			},
			{
				...alias.admin.Menu,
				component: () => import("src/pages/admin/menu/index.vue"),
			},
			{
				...alias.admin.Role,
				component: () => import("src/pages/admin/role/index.vue"),
			},
		],
	}
};

export default router;
