/**
 * @file 公共接口
 * @author svon.me@gmail.com
 */

import * as _ from "lodash-es";
import Permission from "./permission";
import {Captcha} from "src/types/user";
import {Get, tryError} from "@js-lion/api";
import safeGet from "@fengqiaogang/safe-get";
import type {AxiosResponse} from "axios";

const transformResponse = function (text: AxiosResponse) {
	if (text && _.isString(text)) {
		const data = JSON.parse(text);
		const keys: string[] = ["data", "idkey"];
		const value: object = _.assign(_.omit(data, keys), {data: _.pick(data, keys)});
		return JSON.stringify(value);
	}
	return text;
}

export default class {
	// 权限
	permission: Permission = new Permission();

	/**
	 * 验证码
	 */
	@tryError("")
	@Get("/captcha", {transformResponse})
	captcha(): Promise<Captcha> {
		const callback = function (data: object): Captcha {
			const captcha = new Captcha();
			captcha.idKey = safeGet(data, "idkey");
			captcha.captcha = safeGet<string>(data, "data");
			return captcha;
		}
		// @ts-ignore
		return {callback};
	}
}