/**
 * @file 角色与页面管理 - 菜单页面
 * @author svon.me@gmail.com
 */

import {$error, $success} from "@ue/message";
import {Post, required, tryError, validate} from "@js-lion/api";

export default class {
	/**
	 * 创建
	 */
	@tryError(false)
	@$error("创建失败, 请稍后在试")
	@$success("创建成功")
	@Post("/permission/role_menu/update")
	@validate
	async update(@required roleId: string | number, @required list: Array<string | number>): Promise<boolean> {
		const data = {id: roleId, menus: list};
		// @ts-ignore
		return {data};
	}
}