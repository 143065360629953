export enum MenuType {
	directory = 1,
	page,
	link
}

export enum MenuTypeValue {
	directory = "目录",
	page = "页面",
	link = "外链"
}

export class RoleMenu {
	id!: number; // 主键ID
	parentid?: number; // 父级ID
	title!: string; // 名称
	icon?: string; // 图标名称
	path?: string; // 链接路径
	target: number = 1; // 打开方式：1正常 2新开窗口
	type: MenuType = MenuType.page; // 类型：1文件夹 2页面 3外链
	status: number = 1; // 状态：1正常 2禁用 3删除
	sort: number = 1; // 排序序号
	note?: string; // 备注
}

export class RoleUser {
	id!: number; // 主键ID
	username!: string;
	email?: string;
}

const RoleGql = function (target: any): string {
	class Role extends target {
	}

	const data = new Role();
	const keys = Object.keys(data);
	return keys.join("\n");
}


export const RoleMenuGqlKeys: string = RoleGql(RoleMenu);
export const RoleUserGqlKeys: string = RoleGql(RoleUser);
