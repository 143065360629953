import * as _ from "lodash-es";
import safeGet from "@fengqiaogang/safe-get";
import { DBList } from "@fengqiaogang/dblist";

export const treeAmend = function<T = object>(list: T | T[], primary: string = "id", childrenName: string = "children") {
  // 解析数据格式
  const array: T[] = [];
  for (const data of _.concat([], list)) {
    const item = _.omit(data as object, childrenName);
    const children = safeGet<T | T[]>(data, childrenName);

    array.push(item as T);
    
    if (children && Array.isArray(children)) {
      array.push(...children);
    } else if (children) {
      array.push(children as T);
    }
  }
  const db = new DBList<T>([], primary);
  db.insert(array, childrenName);
  return db.childrenDeep();
}