import User from "./user";
import Admin from "./admin";
import Tenant from "./tenant";
import Common from "./common";
import Permission from "./permission/index";

export class Api {
	common: Common = new Common();
	admin: Admin = new Admin();
	user: User = new User();
	tenant: Tenant = new Tenant();
	permission: Permission = new Permission();
}

export default new Api();