import * as _ from "lodash-es";
import { treeAmend } from "src/utils";
import * as message from "@ue/message";
import * as model from "src/utils/model";
import { API, Post, Get, tryError, validate, required } from "@js-lion/api";

import type { Node } from "src/components/tree/type";

export default class Department {

  @tryError(new model.PageResult<Node>())
  @Get("/manager/dept/tree")
  tree(): Promise<model.PageResult<Node>> {
    const callback = function (result: Node | Node[]) {
      // 解析数据格式
      const list = treeAmend<Node>(result, "deptId", "subList");
      // 返回业务需要的数据格式
      return new model.PageResult<Node>(list);
    }
    // @ts-ignore
    return { callback };
  }

  /**
   * 批量新增部门
   * @param name     部门名称
   * @param parentId 父级部门ID
   * @param desc     部门描述内容
   * @returns 
   */
  @tryError(false)
  @message.$error()
  @message.$success("添加成功!")
  @Post("/manager/dept/save/batch")
  @validate
  insert(
    @required name: string | string[],
    @required parentId: Array<string | number> | string | number,
    desc?: string): Promise<boolean> {
    const data = {
      remar: desc,
      deptNames: _.concat([], name),
      parentDeptId: _.concat([], parentId)
    };
    // @ts-ignore
    return { data };
  }

  /**
   * 编辑更新
   * @param name     部门名称
   * @param parentId 父级部门ID
   * @param desc     部门描述内容
   * @returns 
   */
  @tryError(false)
  @message.$error()
  @message.$success("部门名称已更新!")
  @Post("/manager/dept/save")
  @validate
  update(
    @required id: string | number,
    @required name: string,
    @required parentId: string | number | Array<string | number>,
    desc?: string): Promise<boolean> {
    const data = {
      remar: desc,
      deptId: id,
      deptName: name,
      // @ts-ignore
      parentDeptId: [].concat([], parentId)
    };
    // @ts-ignore
    return { data };
  }

  /**
   * 删除
   * @param name 
   */
  @tryError(false)
  @message.$error()
  @message.$success("删除成功 !")
  @validate
  async remove(@required id: string | number | Array<string | number>): Promise<boolean> {
    const api = new API();
    const list = _.concat([], id).map(function (value: string | number) {
      return api.delete(`/manager/dept/delete/${value}`);
    });
    try {
      await Promise.all(list);
    } catch (error) {
      return Promise.reject(error);
    }
    return true;
  }
}