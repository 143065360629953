import * as alias from "../alias";
import type { RouteRecordRaw } from "vue-router";

const router = function(): RouteRecordRaw {
  return {
    path: alias.root.path,
    component: () => import("src/layout/normal/index.vue"),
    children: [
      {
        path: alias.root.tenantList.path,
        name: alias.root.tenantList.name,
        component: () => import("src/pages/root/tenant_management/tenant_list.vue"),
      },
    ],
  }
};

export default router;
